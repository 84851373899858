import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import '../Login.css';
import logo from '../Logo.png';
import logoR from '../LogoR.png';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore"; 
import db from "../../firebase";


const Register = () => {
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({
        email: "",
        password: "",
        userType: "Technology",
        contact:"",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value,
        });
    };

    const [emp, setEmp] = useState("employee");
    const handleEmpType = (e) => {
        e.preventDefault();
        emp === "employee" ? setEmp("Redient") : setEmp("employee");
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { email, password, userType, contact } = userDetails;
        
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in successfully.
            console.log("Registered")
            const user = userCredential.user;
            console.log(user);
            await addDoc(collection(db, "users"), {
                email: user.email,
                userUID: user.uid,
                userType: userType,
                password: password,
                contact: contact,
              });
            navigate("/Dashboard");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode," + ", errorMessage);
            // ..
        });
        
    };


    useEffect(() => {
        let authToken = sessionStorage.getItem("Auth Token");
        if (!authToken) {
            navigate("/");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='body'>
            <div className="container">
                <div className="box1">
                    <div className="logo">
                        <img src={logo} alt="preview logo" />
                    </div>
                </div>
                <div className="box2">
                    <div className="logo">
                        <img src={logoR} alt="preview logo" />
                    </div>
                    <form className="formData" method="POST">
                        <div className="email">
                            <i className="bi bi-person-fill"></i>
                            <input type="email" name="email" placeholder="Username" value={userDetails.email}
                                onChange={handleChange} />
                        </div>
                        <div className="password">
                            <i className="bi bi-lock-fill"></i>
                            <input type="password" name="password" placeholder="Password" value={userDetails.password}
                                onChange={handleChange} />
                        </div>
                        <div className="contact">
                            <i className="bi bi-phone-fill"></i>
                            <input type="number" name="contact" placeholder="contact" value={userDetails.contact}
                                onChange={handleChange} />
                        </div>
                        <div className="dropdown">
                            <select id="designation"
                                name="designation"
                                defaultValue={"Emp"}
                                onChange={handleEmpType}>
                                <option value={"Emp"} >Employee</option>
                                <option value={"Redient"} >Redient</option>
                            </select>
                            {
                                emp === "employee" ?
                                    <select id="userType" name="userType" onChange={handleChange} value={userDetails.userType}>
                                        <option value={"Technology"}>Technology</option>
                                        <option value={"BDvl"}>Business Development</option>
                                        <option value={"HRs"}>Human Resource</option>
                                    </select>
                                    :
                                    <select id="userType" name="userType" onChange={handleChange} value={userDetails.userType}>
                                        <option value={"TL"}>Team Leader</option>
                                        <option value={"SD"}>Service Desk</option>
                                        <option value={"ITM"}>IT Manager</option>
                                        <option value={"Admin"}>Super Admin</option>
                                    </select>
                            }
                        </div>
                        <input type="submit" value="Register" className="button" onClick={handleSubmit} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register
