/* eslint-disable no-unused-vars */
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import UsersDetailsTable from "./UsersDetailsTable";

import addUser from "../../addUser.png";

const AddUsers = () => {

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: 8,
        px: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper
            elevation={5}
            sx={{
              px: 2,
              py: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              overflow: "hidden",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: grey["50"],
                cursor: "pointer",
              },
            }}
          >
            <h1 style={{}}>Employees</h1>
            <br />
            <div
              style={{
                width: 200,
                margin: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <img
                  src={addUser}
                  alt="FileIcon"
                  style={{ width: "50%" }}
                />
                <br />
                <h4>Add Employee</h4>
                <br />
              </div>
            </div>
            <br />
            <Link href="Register">
              <Button variant="contained" component="span" sx={{ width: '200px', fontSize: '16px' }}>
                Add
              </Button>
            </Link>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <UsersDetailsTable />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUsers;
