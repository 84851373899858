import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyC99Pu6g7Ka-MZ3WPjhEG-hRhZc2KuzbII",
  authDomain: "learning-management-system-lms.firebaseapp.com",
  projectId: "learning-management-system-lms",
  storageBucket: "learning-management-system-lms.appspot.com",
  messagingSenderId: "262218304693",
  appId: "1:262218304693:web:73f60690aa25548cce02fc",
  measurementId: "G-H60EPD7RV0"
};

!getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase
const db = getFirestore();
const auth = getAuth();
const storage = getStorage();

export default db;

export { auth, storage };
