/* eslint-disable no-unused-vars */
import db, { storage } from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { v4 as uuidV4 } from "uuid";

import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  onSnapshot,
  where,
  query,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export default function AddFileButton({ currentFolder }) {
  const [openFile, setOpenFile] = useState(false);
  const [file, setFile] = useState();
  const [uploadingFiles, setUploadingFiles] = useState([]);

  // const { currentUser } = useAuth()
  // console.log(uploadingFiles);
  function handleUpload(e, async) {
    e.preventDefault();
    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);

    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.join("/")}/${file.name}`
        : `${currentFolder.path.join("/")}/${currentFolder.name}/${file.name}`;

    const uploadTask = uploadBytesResumable(
      ref(storage, `/files/${filePath}`),
      file
    );
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.map((uploadFile) => {
            if (uploadFile.id === id) {
              return { ...uploadFile, progress: progress };
            }
            return uploadFile;
          });
        });
      },
      () => {
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.map((uploadFile) => {
            if (uploadFile.id === id) {
              return { ...uploadFile, error: true };
            }
            return uploadFile;
          });
        });
      },
      () => {
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.filter((uploadFile) => {
            return uploadFile.id !== id;
          });
        });

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onSnapshot(
            query(
              collection(db, "file"),
              where("name", "==", file.name),
              where("folderId", "==", currentFolder.id)
            ),
            (existingFiles) => {
              const existingFile = existingFiles.docs[0];
              if (existingFile) {
                console.log("File Overwrite");
              } else {
                addDoc(collection(db, "file"), {
                  url: downloadURL,
                  name: file.name,
                  createdAt: serverTimestamp(),
                  folderId: currentFolder.id,
                  type: file.type,
                });
                console.log("File Added");
              }
            }
          );
          console.log("File available at", downloadURL);
        });
      }
    );
    setFile("");
    setOpenFile(false);
  }

  return (
    <span style={{ zIndex: "15" }}>
      <IconButton onClick={() => setOpenFile(true)}>
        <UploadFileIcon fontSize="large" color="success" />
      </IconButton>
      <Dialog open={openFile} onClose={() => setOpenFile(false)}>
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose Files you want to Upload.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="fileUpload"
            label="File Upload"
            type="file"
            fullWidth
            variant="standard"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFile(false)}>Cancel</Button>
          <Button onClick={handleUpload}>Upload</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
