import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';

import useContextMenu from "../../contexts/useContextMenu"

const Menu = () => {
    
    const { anchorPoint, show } = useContextMenu();

    if (show) {
        return (
            <Paper sx={{ width: 320, maxWidth: '100%' }} style={{ position: 'absolute', top: anchorPoint.y, left: anchorPoint.x }}>
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <UploadFileIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Upload Files</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘X
                        </Typography>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <CreateNewFolderIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Create Folder</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘C
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => window.location.reload(false)}>
                        <ListItemIcon>
                            <RefreshIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Refresh</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            ⌘R
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => {
                        window.open("/", "_self")
                        window.close()
                    }}>
                        <ListItemIcon>
                            <CloseIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Exit</ListItemText>
                    </MenuItem>
                </MenuList>
            </Paper>
        );
    }
    return <></>;
};

export default Menu;