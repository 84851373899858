import React from "react";
import { Link } from "react-router-dom";
import folderIcon from "../../Folder.png";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { doc, deleteDoc } from "firebase/firestore";
import db from "../../firebase";


export default function Folder({ folder }) {
  const deleteFolder = async (id) => {
    const folderDoc = doc(db, "folder", id);
    window.confirm("Are you sure you want to delete this folder?") && deleteDoc(folderDoc, id);
    // setMsg('Deleted Successfully!');
    // handleClick();
    // console.log(id, uid);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexWrap: "wrap",
        flexDirection: "row",
        "& > :not(style)": {
          m: 1,
          width: 150,
          height: 150,
        },
      }}
    >
      {
        folder.parentId!==null && <IconButton
        aria-label="delete"
        color="error"
        style={{ width: "30px" }}
        onClick={() => {
          deleteFolder(folder.id, folder.name);
          console.log(folder);
        }}
      >
        <DeleteIcon />
      </IconButton>
      }
      {/* <input
        type="checkbox"
        name="Folder"
        onChange={(e) => folderSelected(folder)}
      /> */}
      <Link
        to={{
          pathname: `/folder/${folder.id}`,
          state: { folder: folder },
        }}
        variant="outline"
        style={{
          textDecoration: "none",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <img
            style={{ maxWidth: "100px" }}
            alt="Folder Icon"
            src={folderIcon}
          />
          <span style={{ fontSize: "1rem" }}>{folder.name}</span>
        </Paper>
      </Link>
    </Box>
  );
}
