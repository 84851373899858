import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function createData(users) {
  return { users };
}

const rows = [
  createData('Human Resources'),
  createData('Team Leader'),
  createData('IT Manager'),
  createData('Service Desk'),
  createData('Business Development'),
  createData('Technology'),
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell sx={{ fontSize: '20px', p: 4 }} background='primary' >Employees</TableCell>
            <TableCell sx={{ p:4 }} style={{display:'flex', justifyContent:'space-evenly'}}>
              <Button variant="contained">Update</Button>
              <Button variant="contained" color="error">Cancel</Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.users}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ p: 4 }}>
                {row.users}
              </TableCell>

              <TableCell align="right">
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} />
                </FormGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
