/* eslint-disable no-unused-vars */
import * as React from 'react';
import db from "../../firebase";
import { collection, getDocs, updateDoc, doc, deleteDoc } from "firebase/firestore";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, TextField, Button, Grid, Snackbar, Box, Modal } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAuth, deleteUser } from "firebase/auth";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AcccessibleTable() {

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [userDetails, setUserDetails] = React.useState({
    password: "",
    contact: "",
  });
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const [users, setUsers] = React.useState([]);
  const usersCollectionRef = collection(db, "users");
  const [msg, setMsg] = React.useState('');

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const updateUser = async (id, password, contact) => {
    console.log(id, password, contact);
    const userDoc = doc(db, "users", id);
    const newFields = { password: password, contact: contact };
    updateDoc(userDoc, newFields);
    setMsg('Updated Successfully!');
    handleClick();
    // updateDoc(usersCollectionRef.doc(id), newFields);
    setUserDetails({ password: '', contact: '' });
  }

  const deleteUser = async (id, uid) => {
    const userDoc = doc(db, "users", id);
    deleteDoc(userDoc, id);
    setMsg('Deleted Successfully!');
    handleClick();

    deleteUser(uid).then(() => {
      console.log("Deleted Successfully!");
    }).catch((error) => {
      // An error ocurred
      console.log(error);
      // ...
    });



  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  React.useEffect(() => {
    const getUsers = async () => {
      const usersCollection = await getDocs(usersCollectionRef);
      setUsers(usersCollection.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      // setUsers(usersCollection.docs.map((doc) => doc.data()));
    };
    getUsers();
  }, [usersCollectionRef]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
      {users.map((user) => (
        <Accordion key={user.id} expanded={expanded === user.userUID} onChange={handleChange(user.userUID)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            style={{
              minWidth: '320px',
              maxHeight: '100px',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
              <Typography sx={{
                width: '50%',
                minWidth: '300px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }} variant="caption">
                UID: {user.userUID}
              </Typography>
              <Typography sx={{
                width: '50%',
                minWidth: '300px',
                color: 'text.secondary',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }} variant="caption">
                Email: {user.email}
              </Typography>
            </div>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={1} rowSpacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                User Type: {user.userType}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  id="password"
                  label="Password"
                  name="password"
                  defaultValue={user.password}
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  id="contact"
                  name="contact"
                  label="Contact"
                  defaultValue={user.contact}
                  onChange={handleTextChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} style={{ textAlign: 'right' }}>
                <IconButton aria-label="edit" color="secondary"
                  onClick={() => { updateUser(user.id, userDetails.password === "" ? user.password : userDetails.password, userDetails.contact === "" ? user.contact : userDetails.contact) }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" color="error"
                  onClick={() => { deleteUser(user.id, user.userUID) }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

      ))}
    </>
  );
}
