import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Link from "@mui/material/Link";
import MuiAppBar from "@mui/material/AppBar";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { amber } from "@mui/material/colors";

import logoR from './../LogoR.png';

var CryptoJS = require("crypto-js");


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function MediaBar() {

    const { fileLink } = useParams()
    const { fileType } = useParams()

    const theme = createTheme({
        palette: {
            primary: {
                main: amber[400],
            },
        },
    });
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.removeItem("Auth Token");
        // navigate("/");
    };

    // React.useEffect(() => {
    //     window.frames.oncontextmenu = function (e) {
    //         e.PreventDefault();
    //         return false;
    //     }
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            {/* <MediaFile.Consumer> */}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        // onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img src={logoR} alt="logoR" style={{ width: '45px' }} />
                        &nbsp;&nbsp;
                        <Link href="/" sx={{ color: 'black', '&:hover': { textDecoration: 'none' } }}>
                            Redient Security
                        </Link>
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            style={{ width: '60px' }}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>
                                <Link variant="body1" underline="none" color="inherit">
                                    Profile
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                                <Link variant="body1" underline="none" color="inherit">
                                    Dashboard
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                                <Link variant="body1" underline="none" color="inherit">
                                    Add Account
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                                <Link
                                    onClick={handleLogout}
                                    variant="body1"
                                    underline="none"
                                    color="inherit"
                                >
                                    Logout
                                </Link>
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            {/* </MediaFile.Consumer> */}

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',

            }}>
                {fileType === 'video' ? (
                    <video width="75%" height="75%" controls controlsList="nodownload">
                        <source src={CryptoJS.AES.decrypt(fileLink.replaceAll("Ja1p0r", "/"), "IAMWORKINGASINTERN").toString(CryptoJS.enc.Utf8)} />
                    </video>
                ) : (
                    <iframe
                        src={CryptoJS.AES.decrypt(fileLink.replaceAll("Ja1p0r", "/"), "IAMWORKINGASINTERN").toString(CryptoJS.enc.Utf8) + "#toolbar=0"}
                        style={{ marginTop: '150px', width: '90%', height: '100%' }}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen
                        title="Media-Files Only"
                        id="MainMediaIFrame"
                    />
                )}
            </div>
        </ThemeProvider>

    )
}