import React from "react";
import { Link, Box, Paper } from "@mui/material";
import fileIcon from "../../file.png";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { doc, deleteDoc } from "firebase/firestore";
import db from "../../firebase";

var CryptoJS = require("crypto-js");

export default function File({ file }) {
  var type = file.type;
  var fileType = "app";

  if (type.includes("video")) fileType = "video";
  if (type.includes("image")) fileType = "image";

  const deleteFile = async (id) => {
    const fileDoc = doc(db, "file", id);
    window.confirm("Are you sure you want to delete this file?") &&
      deleteDoc(fileDoc, id);
    // setMsg('Deleted Successfully!');
    // handleClick();
    // console.log(id, uid);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        flexWrap: "wrap",
        flexDirection: "row",
        "& > :not(style)": {
          m: 1,
          width: 150,
          height: 150,
        },
      }}
    >
      <IconButton
        aria-label="delete"
        color="error"
        style={{ width: "30px" }}
        onClick={() => {
          deleteFile(file.id);
        }}
      >
        <DeleteIcon />
      </IconButton>
      <Link
        href={
          "/Media/" +
          fileType +
          "/" +
          CryptoJS.AES.encrypt(file.url, "IAMWORKINGASINTERN")
            .toString()
            .replaceAll("/", "Ja1p0r")
        }
        target="_blank"
      >
        <Paper
          elevation={0}
          sx={{
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "100px" }} alt="Folder Icon" src={fileIcon} />
          <br />
          <span
            style={{
              whiteSpace: "nowrap",
              width: "125px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {file.name}
          </span>
        </Paper>
      </Link>
    </Box>
  );
}
