/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Link from "@mui/material/Link";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { amber } from "@mui/material/colors";
import { getAuth } from "firebase/auth";
import {
    collection,
    getDocs,
    where,
    query
} from "firebase/firestore";
import db from "../../firebase";

import logoR from './../LogoR.png';
import UploadFiles from "./UploadFiles";
import AddUsers from "./AddUsers";
import Permission from "./Permission";

const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function Dashboard() {

    const navigate = useNavigate();

    const theme = createTheme({
        palette: {
            primary: {
                main: amber[400],
            },
        },
    });
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.removeItem("Auth Token");
        navigate("/");
        setTypeUser(null);
    };

    const [typeUser, setTypeUser] = useState(null);

    // f.forEach((doc) => {
    //     setTypeUser(doc.data().userType);
    //     console.log(doc.data());
    //     doc !== null ?
    //         console.log(doc.id, " => ", doc.data()) :
    //         console.log("No Data Found");
    // })

    // The user's ID, unique to the Firebase project. Do NOT use
    // this value to authenticate with your backend server, if
    // you have one. Use User.getToken() instead.
    const auth = getAuth();
    const user = auth.currentUser;

    const getUserType = async () => {
        if (user) {
            // The user object has basic properties such as display name, email, etc.
            await getDocs(query(collection(db, "users"),
                where("email", "==", user.email),
                where("userUID", "==", user.uid),
            )).then((response) => {
                setTypeUser(response.docs[0].data().userType);
                return typeUser; 
            });
        }
        // return 
    }

    // useEffect(() => {

    // }, []);

    useEffect(() => {
        let authToken = sessionStorage.getItem("Auth Token");
        if (authToken) {
            navigate("/Dashboard");
        }
        if (!authToken) {
            navigate("/");
        }

        getUserType();
        if (typeUser === null) {
            navigate("/Dashboard");
        }
        if (typeUser === "Technology") {
            navigate("/folder/f0KqBUTanxCRqLyQuhd8");
            // navigate("/folder/kLCk362vqgOACeruUhfK");
        }
        if (typeUser === "BDvl") {
            // navigate("/folder/g0xIo8mhe77JyUIleglA");
            navigate("/folder/6zWpgvCUoIWz6BQiIp3y");
        }
        if (typeUser === "HRs") {
            navigate("/folder/aJUpFSDeK72WCd6gLHow");
            // navigate("/folder/KHpF0vZJpEBPUDwpJYlN");
        }
        if (typeUser === "TL") {
            navigate("/folder/OqnrQyC7zIZXaraSpGTB");
            // navigate("/folder/gHLu0zuG9FEJBpQZUnwQ");
        }
        if (typeUser === "SD") {
            // navigate("/folder/CNgTOXxK4SJ9shM9GZS8");
            navigate("/folder/rVAmCKsLngnZcObJ0toM");
        }
        if (typeUser === "ITM") {
            navigate("/folder/aWemVxOGoMU1gcN4zUqJ");
            // navigate("/folder/t8KcDwXV0LXfyBDYKK1p");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeUser]);


    const [dashboardItem, setDashboardItem] = useState("Dashboard");
    const handleDashboard = (dashboardItem) => {
        switch (dashboardItem) {
            case "Dashboard":
                return (<UploadFiles type={typeUser} />);
            case "Account":
                return (<AddUsers />);
            case "Permissions":
                return (<Permission />);
            default:
                <h1>Welcome</h1>;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    pt: 8,
                    px: 2,
                }}
            >
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <img src={logoR} alt="logoR" style={{ width: '45px' }} />
                            &nbsp;&nbsp;
                            <Link href="/" sx={{ color: 'black', '&:hover': { textDecoration: 'none' } }}>
                                Redient Security
                            </Link>
                        </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                style={{ width: '60px' }}
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <Link variant="body1" underline="none" color="inherit" onClick={() => { setDashboardItem("Dashboard") }}>
                                        Dashboard
                                    </Link>
                                </MenuItem>
                                {
                                    typeUser === "Admin" ?
                                        <span>
                                        <MenuItem onClick={handleMenuClose}>
                                            <Link variant="body1" underline="none" color="inherit" onClick={() => { setDashboardItem("Account") }}>
                                                Add Account
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleMenuClose}>
                                            <Link variant="body1" underline="none" color="inherit" onClick={() => { setDashboardItem("Permissions") }}>
                                                Manage Permissions
                                            </Link>
                                        </MenuItem>
                                        </span>
                                        : null
                                }
                                <MenuItem onClick={handleMenuClose}>
                                    <Link
                                        onClick={handleLogout}
                                        variant="body1"
                                        underline="none"
                                        color="inherit"
                                    >
                                        Logout
                                    </Link>
                                </MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {handleDashboard(dashboardItem)}
                </Box>
            </Box>
        </ThemeProvider>

    );
}
