import React from "react";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';


import AddFolderButton from "./AddFolderButton";
import AddFileButton from "./AddFileButton";
import FolderBreadcrumbs from "./FolderBreadcrumbs";
import { useFolder } from "../../hooks/useFolder"
import { useParams } from "react-router-dom"
import Folder from "./Folder";
import File from "./File";

const UploadFiles = ({ type }) => {

    var { folderId } = useParams()
    // const { state = {} } = useLocation()

    const { folder, childFolders, childFiles } = useFolder(folderId)

    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Paper elevation={4}
                sx={{
                    width: '100%',
                    px: 4,
                    pt: 2,
                    pb: 4,
                }}>

                {
                    type === null ?
                        (
                            <center><h3>Login Again to view the Content</h3></center>
                        )
                        :
                        (type === "Admin" ?
                            (
                                <>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            px: 2,
                                        }}
                                    >
                                        <div sx={{ width: '90%' }}>
                                            <FolderBreadcrumbs currentFolder={folder} />
                                        </div>
                                        <Box sx={{
                                            width: '10%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-around',
                                        }}>
                                            <AddFileButton currentFolder={folder} />
                                            <AddFolderButton currentFolder={folder} />

                                        </Box>
                                    </Box>
                                    <br /><hr /><br /><br />
                                    {
                                        (childFolders.length > 0 || childFiles.length > 0) && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                }}>
                                                {childFolders.map((childFolder) => (
                                                    <div key={childFolder.id}>
                                                        <Folder folder={childFolder} />
                                                    </div>
                                                )
                                                )}
                                                {childFiles.map((childFile) => (
                                                    <div key={childFile.id}>
                                                        <File file={childFile} />
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        px: 2,
                                    }}
                                >
                                    {
                                        (childFolders.length > 0 || childFiles.length > 0) && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                }}>
                                                {childFolders.map((childFolder) => (
                                                    <div key={childFolder.id}>
                                                        <Folder folder={childFolder} />
                                                    </div>
                                                )
                                                )}
                                                {childFiles.map((childFile) => (
                                                    <div key={childFile.id}>
                                                        <File file={childFile} />
                                                    </div>
                                                )
                                                )}
                                            </div>
                                        )
                                    }
                                </Box>
                            )
                        )
                }
            </Paper>
        </Box>
    )
}

export default UploadFiles;