import React, { useState } from "react";
import db from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

export default function AddFolderButton({ currentFolder }) {
  // const { currentUser } = useAuth()

  const [openFolder, setOpenFolder] = useState(false);
  const [folderName, setFolderName] = useState("");

  const handleCreateFolder = async (e) => {
    e.preventDefault();

    if (currentFolder == null) return;
    if (folderName.length < 1) return;

    const path = [...currentFolder.path];

    if (currentFolder !== ROOT_FOLDER) {
      path.push({ name: currentFolder.name, id: currentFolder.id });
    }

    console.log(currentFolder);
    await addDoc(collection(db, "folder"), {
      name: folderName,
      parentId: currentFolder.id,
      createdAt: serverTimestamp(),
      path: path,
    });

    setFolderName("");
    setOpenFolder(false);
  };

  return (
    <span style={{zIndex:'15'}}>
      <IconButton onClick={() => setOpenFolder(true)}>
        <CreateNewFolderIcon fontSize="large" color="success" />
      </IconButton>
      <Dialog open={openFolder} onClose={() => setOpenFolder(true)}>
        <DialogTitle>New Folder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Name of New Folder you want to Create.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="folderName"
            label="Folder Name"
            type="text"
            fullWidth
            variant="standard"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolder(false)}>Cancel</Button>
          <Button onClick={handleCreateFolder}>Create</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
