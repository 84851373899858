import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Login from "./components/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import MediaBar from "./components/Dashboard/MediaBar";
import Register from "./components/Dashboard/Register";
import ContextMenu from "./components/Dashboard/ContextMenu";

function App() {
  return (
    <div className="App">
      <ContextMenu />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/folder/:folderId" element={<Dashboard />} />
          <Route path="/media/:fileType/:fileLink" element={<MediaBar />} />
          <Route path="/Register" element={<Register />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
