import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as Links } from "react-router-dom";
import Link from "@mui/material/Link";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { Typography } from "@mui/material";

export default function FolderBreadcrumbs({ currentFolder }) {
  let path = currentFolder === ROOT_FOLDER ? [] : [ROOT_FOLDER];
  if (currentFolder) path = [...path, ...currentFolder.path];

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" maxItems={3}>
        {path.map((folder, index) => (
          <Link
            key={folder.id}
            underline="hover"
            as={Links}
            to={{
              pathname: folder.id ? `/folder/${folder.id}` : "/Dashboard",
              state: { folder: { ...folder, path: path.slice(1, index) } },
            }}
            color="inherit"
          >
            {folder.name}
          </Link>
        ))}
        {currentFolder && (
          <Typography color="inherit">{currentFolder.name}</Typography>
        )}
      </Breadcrumbs>
    </>
  );
}
