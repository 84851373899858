import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './Login.css';
import logo from './Logo.png';
// import logoR from './LogoR.png';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Login = () => {
    const navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({
        email: "",
        password: "",
        userType: "Technology",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({
            ...userDetails,
            [name]: value,
        });
    };

    const [msg, setMsg] = React.useState('All Good!');
    
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    // const [emp, setEmp] = useState("employee");
    // const handleEmpType = (e) => {
    //     e.preventDefault();
    //     emp === "employee" ? setEmp("Redient") : setEmp("employee");
    // }
    const handleSubmit = async (e) => {
        e.preventDefault();
        handleClick();
        try {
            const { email, password } = userDetails;
            const authentication = getAuth();
            signInWithEmailAndPassword(authentication, email, password).then(
                (response) => {
                    navigate("/Dashboard");
                    sessionStorage.setItem(
                        "Auth Token",
                        response._tokenResponse.refreshToken
                    );
                }
            ).catch((error) => {
                if (error.code === 'auth/wrong-password') {
                    setMsg('Please check the Password')
                    console.log('Please check the Password');
                }
                if (error.code === 'auth/user-not-found') {
                    console.log('Please check the Email');
                    setMsg('Please check the Email');
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        let authToken = sessionStorage.getItem("Auth Token");
        if (authToken) {
            // navigate("/Dashboard");
        }
    }, []);

    return (
        <div className='body'>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={msg}
                action={action}
            />
            <div className="container">
                <div className="box1">
                    <div className="logo">
                        <img src={logo} alt="preview logo" />
                    </div>
                </div>
                <div className="box2">
                    <div className="logo">
                        {/* <img src={logoR} alt="preview logo" /> */}
                            <h5>Learning Management System</h5>
                    </div>
                    <form className="formData" method="POST">
                        <div className="email">
                            <i className="bi bi-person-fill"></i>
                            <input type="email" name="email" placeholder="Username" value={userDetails.email}
                                onChange={handleChange} />
                        </div>
                        <div className="password">
                            <i className="bi bi-lock-fill"></i>
                            <input type="password" name="password" placeholder="Password" value={userDetails.password}
                                onChange={handleChange} />
                        </div>
                        <input type="submit" value="Login" className="button" onClick={handleSubmit} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login
