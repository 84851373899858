import { useReducer, useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext"
import db from "../firebase";
import {
  doc,
  getDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

const ACTIONS = {
  SELECT_FOLDER: "select-folder",
  UPDATE_FOLDER: "update-folder",
  SET_CHILD_FOLDERS: "set-child-folders",
  SET_CHILD_FILES: "set-child-files",
};

export const ROOT_FOLDER = { name: "Home", id: null, path: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SELECT_FOLDER:
      return {
        folderId: payload.folderId,
        folder: payload.folder,
        childFiles: [],
        childFolders: [],
      };
    case ACTIONS.UPDATE_FOLDER:
      return {
        ...state,
        folder: payload.folder,
      };
    case ACTIONS.SET_CHILD_FOLDERS:
      return {
        ...state,
        childFolders: payload.childFolders,
      };
    case ACTIONS.SET_CHILD_FILES:
      return {
        ...state,
        childFiles: payload.childFiles,
      };
    default:
      return state;
  }
}

export function useFolder(folderId = null, folder = null) {
  const [state, dispatch] = useReducer(reducer, {
    folderId,
    folder,
    childFolders: [],
    childFiles: [],
  });
  // const { currentUser } = useAuth()

  useEffect(() => {
    dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } });
  }, [folderId, folder]);

  useEffect(() => {
    if (folderId == null) {
      return dispatch({
        type: ACTIONS.UPDATE_FOLDER,
        payload: { folder: ROOT_FOLDER },
      });
    }

    getDoc(doc(db, "folder", folderId))
      .then((doc) => {
        // console.log({ id: doc.id, ...doc.data() })
        dispatch({
          type: ACTIONS.UPDATE_FOLDER,
          payload: { folder: { id: doc.id, ...doc.data() } },
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.UPDATE_FOLDER,
          payload: { folder: ROOT_FOLDER },
        });
      });
  }, [folderId]);

  useEffect(() => {
    return onSnapshot(
      query(
        collection(db, "folder"),
        where("parentId", "==", folderId),
        orderBy("createdAt", "asc")
      ),
      (snapshot) => {
        // eslint-disable-next-line array-callback-return
        dispatch({
          type: ACTIONS.SET_CHILD_FOLDERS,
          payload: {
            childFolders: snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          },
        });
      }
    );
  }, [folderId]);

  useEffect(() => {
    return onSnapshot(
      query(
        collection(db, "file"),
        where("folderId", "==", folderId),
        orderBy("createdAt", "asc")
      ),
      (snapshot) => {
        // eslint-disable-next-line array-callback-return
        dispatch({
          type: ACTIONS.SET_CHILD_FILES,
          payload: {
            childFiles: snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })),
          },
        });
      }
    );
    // payload: { childFiles: snapshot.docs.map(database.formatDoc) },
  }, [folderId]);

  return state;
}
